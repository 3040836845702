// Step 1: Import React
import * as React from 'react';
import Layout from '../components/Layout';
import { Container, Row } from "reactstrap";
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 


// Step 2: Define your component
const EbookFormats = () => {
  const { t } = useTranslation();

  return (
    <main>
      <Layout>
        <title>eBook formats</title>
        <Container className="siteStyl">
          <br />
          <Row>
          <p>{t('format1')}</p> 
          <p>{t('format2')}</p>
          <p>{t('format3')}</p>
          <p>{t('format4')}</p>
          <p>{t('format5')}</p>
          <p>{t('format6')}</p>
          </Row>
        </Container>
      </Layout>
    </main>
    )
}

// Step 3: Export your component
export default EbookFormats

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["formats", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;